import { createRoot } from 'react-dom/client'
import './styles.css'
import App from './App'
// import { Logo } from '@pmndrs/branding'

function Overlay() {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
      {/* <a href="#" style={{ position: 'absolute', bottom: 40, left: 90, fontSize: '13px' }}>
      </a> */}
      <div style={{ position: 'absolute', top: 40, left: 40, fontSize: '13px', fontStyle: 'italic' }}>
        <h1 style={{ fontSize: '16px', margin: 0, padding: 0, lineHeight: 1 }}>Alexandre Zaganelli</h1>
        Software Engineer
      </div>

      <a href="https://cal.com/alexzaganelli/30min?date=2024-02-26&month=2024-02" style={{ position: 'absolute', top: 120, left: 40, fontSize: '13px' }} target="_blank">
        — Book a call —
      </a>

      <a href="https://www.linkedin.com/in/alexzaganelli/" style={{ position: 'absolute', top: 160, left: 40, fontSize: '13px' }} target="_blank">
        — LinkedIn —
      </a>

      <a href="https://github.com/alexzaganelli" style={{ position: 'absolute', top: 140, left: 40, fontSize: '13px' }} target="_blank">
        — GitHub —
      </a>
    </div>
  )
}

createRoot(document.getElementById('root')).render(
  <>
    <App />
    <Overlay />
    {/* <Logo style={{ position: 'absolute', bottom: 40, left: 40, width: 30 }} /> */}
    <img src="/zaganelli_logo_white.png" style={{ position: 'absolute', bottom: 40, right: 40, width: 240 }} />
  </>
)
